<template>
  <div>
    <!-- <GeneralBlock
      :gui="gui"
      @settings-changed="$emit('settings-changed', $event)"
    /> -->
    <IlluminationBlock
      :gui="gui"
      @settings-changed="$emit('settings-changed', $event)"
    />
    <ModelPositioningBlock
      :gui="gui"
      @settings-changed="$emit('settings-changed', $event)"
    />
    <CameraBlock
      :gui="gui"
      @settings-changed="$emit('settings-changed', $event)"
    />
    <EffectsBlock
      :gui="gui"
      @settings-changed="$emit('settings-changed', $event)"
    />
  </div>
</template>
<script>
const IlluminationBlock = () => import('./world/IlluminationBlock')
const ModelPositioningBlock = () => import('./world/ModelPositioningBlock')
const EffectsBlock = () => import('./world/EffectsBlock')
const GeneralBlock = () => import('./world/GeneralBlock')
const CameraBlock = () => import('./world/CameraBlock')

export default {
  name: 'WorldPanel',

  components: {
    IlluminationBlock,
    ModelPositioningBlock,
    EffectsBlock,
    GeneralBlock,
    CameraBlock
  },

  props: {
    gui: {
      required: true,
      type: Object
    }
  }
}

</script>
